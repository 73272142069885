import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

export const TABLA_MULTIMEDIA = 'EurekaCrMultimedia'

export const multimediaAPI = createApi({
    reducerPath: 'multimediaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Multimedias'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        crearMultimedia: builder.mutation({
            /**
             * @param {{ tabla: string, media: { id: number, file: object, carpeta: string, col: string }  }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(data?.tabla ?? TABLA_MULTIMEDIA)
                let media = data.media

                if (media?.id) body = body.where('id', media.id)
                // else se crea el registro

                body = body.uploadFile(media.file, media.carpeta, media.col)

                let formulario = new FormData();
                Object.entries(body).forEach(([key, value]) => {
                    formulario.append(key, value);
                })

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: { 'Authorization' : `Bearer ${token()}` },
                    enctype: "multipart/form-data",
                    body: formulario
                }
            }
        }),
        relacionarMultimedia: builder.mutation({
            /**
             * @param {{ id: number, tabla: string, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(data?.tabla ?? TABLA_MULTIMEDIA)
                if(data?.id) {
                    body = body.where('id', data?.id).update(data.body)
                }
                else { 
                    body = body.create(data.body)
                }

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerMultimedia: builder.query({
            /**
             * @param {{ id: number }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_MULTIMEDIA).where('id', data.id).first()

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const { 
    useCrearMultimediaMutation,
    useRelacionarMultimediaMutation,
    useObtenerMultimediaQuery,
} = multimediaAPI