import './Contenido.css'

const Contenido = ({ children }) => {
    return (
        <div className="contenido">
            {children}
        </div>
    )
}

export default Contenido