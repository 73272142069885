import { useState } from 'react'
import nouser from '../../imgs/nouser.jpg'
import iconoEmpresa from '../../imgs/logo.png'
import { capitalize, obtenerLogo } from '../../js/util'
import { Theme, Navegador } from 'eureka-design'
import { usuario as infoUsuario } from 'account-react-eurekasigma'
import { config } from '../../config/config'
import { obtenerRol, ROL_ADMINISTRADOR, ROL_DOCTOR } from '../../js/rol'

import Perfil from '../perfil/Perfil'

import Documentacion from '../perfil/Documentacion'

import ListaPacientes from '../pacientes/lista-pacientes'
import ListaMedicinas from '../medicinas/lista-medicinas'
import FormPaciente from '../pacientes/form-paciente'

import Ganancia from '../perfil/Ganancia'


const Index = () => {
    const [usuario] = useState(infoUsuario())
    const [rol] = useState(obtenerRol(usuario))
    let opciones = {}

    if (rol === ROL_ADMINISTRADOR.nombre) {
        opciones = {
            "Usuarios": {
                opcion: {
                    texto: 'usarios',
                    icono: 'fa-user-md'
                },
                pantallas: {
                    lista_doctores: {
                        props: { usuario },
                        titulo: 'Usuarios',
                        componente: ListaPacientes,
                    },
                    form_paciente: {
                        props: {},
                        titulo: 'Formulario de usuarios',
                        componente: FormPaciente,
                    },
                    // info_doctor: {
                    //     props: {},
                    //     titulo: 'Información del doctor',
                    //     componente: InfoDoctor,
                    // },
                },
            },
            'Productos': {
                opcion: {
                    texto: 'productos',
                    icono: 'fa-cube'
                },
                pantallas: {
                    lista_productos: {
                        props: {},
                        titulo: 'Productos',
                        componente: ListaMedicinas,
                    },
                }
            },
        }
    }
    opciones = {
        ...opciones,
        'Perfil': {
            pantallas: {
                perfil: {
                    props: { rol },
                    titulo: 'Mi perfil',
                    componente: Perfil,
                },
                documentacion: {
                    props: {},
                    titulo: 'Mi documentación',
                    componente: Documentacion,
                },
                ganancia: {
                    props: {},
                    titulo: 'Mis ganancias',
                    componente: Ganancia,
                },
            }
        }
    }

    return (
        <Theme
            darkColor={config.colorSecundario}
            lightColor={config.colorPrimario}
            compareDark={config.colorPrimario}
        >
            <Navegador
                themeDefault="light"
                empresa={{
                    nombre: "Chilango Motors",
                    imagen: iconoEmpresa,
                    version: "v1.0.0-2411181 demo",
                }}
                usuario={{
                    nombre: usuario?.nombre ?? 'Sin nombre',
                    rol: capitalize(rol),
                    imagen: obtenerLogo(usuario?.eureka_cr_multimedia, 'logo', nouser),
                    pantalla: "Perfil"
                }}
                componentes={opciones}
            ></ Navegador>
        </Theme>
    )
}

export default Index