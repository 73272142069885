import { createSlice } from "@reduxjs/toolkit"

export const sesionSlice = createSlice({
    name: 'sesionSlice',
    initialState: {
        user: null,
        token: null,
    },
    reducers: {
        fijaUsuario: (state, actions) => {
            state.user = actions.payload
        },
        fijaToken: (state, actions) => {
            state.token = actions.payload
        },
    }
})

export const { fijaUsuario, fijaToken } = sesionSlice.actions
export default sesionSlice.reducer