import './ModalDetalles.css'

const ModalDetalles = ({
    mostrar = true,
    posicion = 'der',
    onClose = () => {},
    titulo = 'Sin titulo',
    children
}) => {
    return (
        <div 
            className={`fondo-modal-detalles ${mostrar ? 'fondo-modal-detalles--mostrar' : ''}`} 
            onClick={(e) => {
                onClose()
            }}
        >
            <div 
                className={`modal-detalles modal-detalles--${posicion} ${mostrar ? 'modal-detalles--mostrar' : ''}`}
                onClick={(e) => { e.stopPropagation() }}
            >
                <div className="modal-detalles__cabezera">
                    <p className="modal-detalles__cabezera__titulo">{titulo}</p>
                    <i className="fa-solid fa-xmark modal-detalles__cabezera__icono" onClick={onClose}></i>
                </div>
                {children}
            </div>
        </div>
    )
}

export default ModalDetalles