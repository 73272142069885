import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_RECETA = 'EurekaStTransaccione'

export const recetaAPI = createApi({
    reducerPath: 'recetaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Recetas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerRecetas: builder.query({
            /**
             * 
             * @param {{ estatus: string }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_RECETA)
                body = data?.estatus ? body.where('estatus', data.estatus) : body

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                        .with([
                            'eureka_st_transacciones_conceptos.eureka_st_transacciones_periodo',
                            "eureka_st_transacciones_conceptos.eureka_st_producto.eureka_st_nivele",
                            "eureka_st_transacciones_conceptos.eureka_st_producto.eureka_st_productos_almacenes",
                            "eureka_st_transacciones_conceptos.eureka_st_producto.eureka_st_productos_precios",
                            "eureka_st_transacciones_conceptos.eureka_st_producto.eureka_productos_similares",
                        ])
                        .with('eureka_ac_vendedor')
                        .with('eureka_bs_cliente')
                        .with('eureka_st_transacciones_detalles')
                        .get()
                }
            },
        }),
        crearReceta: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_RECETA)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearConceptoReceta: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery('EurekaStTransaccionesConcepto')
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearPeriodoReceta: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery('EurekaStTransaccionesPeriodo')
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearDetalleReceta: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery('EurekaStTransaccionesDetalle')
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const {
    useObtenerRecetasQuery,
    useCrearRecetaMutation,
    useCrearConceptoRecetaMutation,
    useCrearPeriodoRecetaMutation,
    useCrearDetalleRecetaMutation,
} = recetaAPI