import { useEffect, useState } from 'react'
import Index from '../layout/Index'
import { DOMINIO, config } from '../../config/config'
import { CargandoPagina } from 'eureka-design'
import {
    crearCookie,
    configurarVerificacionSesion,
    iniciarProcesoRefrescarToken,
    token,
} from 'account-react-eurekasigma'
import { useSelector } from 'react-redux'
import { useDarRolMutation } from '../../services/rol'
import { ROL_ADMINISTRADOR } from '../../js/rol'

const Inicio = () => {
    const sesionSlice = useSelector(state => state.sesionSlice)

    const [verificandoSesion, cambiarEstadoVerificarSesion] = useState(true)
    const [mensajePantallaCarga, fijaMensajePantallaCarga] = useState('Procesando credenciales, por favor espere...')
    const [darRol] = useDarRolMutation()

    useEffect(() => {
        /**
         * @param {object} usuario 
         */
        const crearRol = async (usuario = {}) => {
            return darRol({
                body: {
                    rol: ROL_ADMINISTRADOR.id,
                    usuario: usuario?.id,
                }
            })
                .unwrap()
                .then((res) => {
                    if (res?.mensaje?.id) {
                        return {
                            ...usuario,
                            eureka_cr_rol_usuarios: [res?.mensaje]
                        }
                    }
                    else return usuario
                })
        }

        /**
         * @param {object} usuario 
         * @param {string} tokenSesion 
         */
        const procesarDatos = async (usuario = {}, tokenSesion = '') => {
            if (usuario?.eureka_cr_rol_usuarios?.length === 0) {
                usuario = await crearRol(usuario)
            }
            // else ya cuenta con rol

            iniciarProcesoRefrescarToken(
                usuario,
                tokenSesion,
                config.tiempoRefrescar,
                guardarDatosSesion,
                false,
                config.inactividad,
                `${DOMINIO}/cerrar_sesion`
            )
            guardarDatosSesion(usuario, tokenSesion)
            cambiarEstadoVerificarSesion(false)
        }

        /**
         * @param {object} usuario 
         * @param {string} tokenSesion 
         */
        const guardarDatosSesion = (usuario = {}, tokenSesion = '') => {
            crearCookie(config.nomCookieUsuario, JSON.stringify(usuario), true, config.expCookie)
            crearCookie(config.nomCookieToken, tokenSesion, true, config.expCookie)
            // crearSesionLocal(usuario.id, usuario.nombre, usuario.correo)
            console.log(token())
        }

        if (verificandoSesion) {
            configurarVerificacionSesion(
                sesionSlice,
                config.nomCookieUsuario,
                config.nomCookieToken,
                `${DOMINIO}/cerrar_sesion`,
                procesarDatos
            )
        }
        else {
            fijaMensajePantallaCarga('Redirigiendo, por favor espere...')
        }
    }, [verificandoSesion])

    if (verificandoSesion)
        return <CargandoPagina
            mensaje={mensajePantallaCarga}
            colorPrincipal={config.colorPrimario}
        />
    else
        return <Index />
}

export default Inicio