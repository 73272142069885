import { useNavigate } from 'react-router-dom'
import { useObtenerUsuarioQuery } from '../../services/usuario'
import { useDispatch } from 'react-redux'
import { CargandoPagina } from 'eureka-design'
import { config } from '../../config/config'
import { useEffect } from 'react'
import { crearCookie } from 'account-react-eurekasigma'
import { fijaToken, fijaUsuario } from '../../feactures/SesionSlice'

const SIN_PERMISOS_ERROR = 401

const ProcesarCredenciales = () => {
    /** Recuperar Parametros de Sesión */
    const parametrosURL = new URLSearchParams(window.location.search)
    const correo = parametrosURL.get('correo')
    const token = parametrosURL.get('token')

    const { data: usuario, isLoading, isError, error } = useObtenerUsuarioQuery({
        token: token,
        col: 'correo',
        val: correo
    })

    const navegacion = useNavigate()
    const dispatch = useDispatch()

    if(isError && error.status === SIN_PERMISOS_ERROR) navegacion('/cerrar_sesion')

    useEffect(() => {
        if(!isLoading) {
            if(usuario === undefined) navegacion('/iniciar_sesion')
            else {
                crearCookie(config.nomCookieUsuario, JSON.stringify(usuario), true, config.expCookie)
                crearCookie(config.nomCookieToken, token, true, config.expCookie)
                dispatch(fijaUsuario(usuario))
                dispatch(fijaToken(token))
                navegacion('/')
            }
        }
        // else seguimos cargando los datos del usuario
    }, [isLoading, usuario, token, dispatch, navegacion])

    return (
        <> 
            <CargandoPagina 
                mensaje="Redirigiendo, por favor espere..."
                colorPrincipal={config.colorPrimario}
            />
        </>
    )
}

export default ProcesarCredenciales