

class EkQuery {
    constructor(props) {
        var Modelo;
        this.tipo = "Json";
        if (typeof props === "string") {
            Modelo = props;
        }
        if (Array.isArray(props)) {
            Modelo = props[0];
            this.tipo = props[1];
        }
        this.Query = {
            modelo: Modelo,
        };
    }

    search(busqueda, columnas, relacion) {
        if (typeof busqueda === "string" && Array.isArray(columnas) && !relacion) {
            this.Query.busqueda = {
                busqueda: busqueda,
                columnas: columnas,
            }
        } else if (typeof busqueda === "string" && Array.isArray(columnas) && relacion) {
            this.Query.busqueda = {
                busqueda: busqueda,
                columnas: columnas,
                relacion: relacion
            }
        }
        return this
    }

    orderBy(columna, by) {
        if (!this.Query.orden) this.Query.orden = [];
        this.Query.orden.push({
            columna: columna,
            by: by,
        })
        return this
    }

    with(relacion, fn) {
        if (!this.Query.relaciones) {
            this.Query.relaciones = [];
        }
        if (typeof relacion == "string" && !fn) {
            this.Query.relaciones.push({
                relacion: relacion
            })
        }
        if (Array.isArray(relacion) && !fn) {
            relacion.forEach(item => {
                this.Query.relaciones.push({
                    relacion: item
                })
            });
        }
        if (typeof relacion === "string" && fn) {
            const result = [...fn(new EkQuery(this.Query.modelo)).Query.condiciones]
            this.Query.relaciones.push({
                relacion: relacion,
                condiciones: result,
                relaciones: fn(new EkQuery(this.Query.modelo)).Query.relaciones
            })

        }
        return this
    }

    where(param1, param2, param3) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }

        if (param1 && param2 !== undefined && !param3 && typeof param1 === "string") {
            this.Query.condiciones.push(
                {
                    tipo: "where",
                    columna: param1,
                    operador: "=",
                    condicion: param2
                }
            )

        }

        if (typeof param1 === "string" && typeof param2 === "string" && param3 !== undefined) {
            this.Query.condiciones.push(
                {
                    tipo: "where",
                    columna: param1,
                    operador: param2,
                    condicion: param3
                }
            )
        }

        if (Array.isArray(param1) && !param2 && !param3) {

            // param1.forEach(condicion => {
            //     this.Query.condiciones.push(
            //         {
            //             tipo: "where",
            //             columna: param1,
            //             operador: param2,
            //             condicion: param3
            //         }
            //     )
            // });

        }
        return this
    }


    orWhere(param1, param2, param3) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }

        if (typeof param1 === "string" && param2 && !param3) {
            this.Query.condiciones.push(
                {
                    tipo: "orWhere",
                    columna: param1,
                    operador: "=",
                    condicion: param2
                }
            )
        }

        if (typeof param1 === "string" && typeof param2 === "string" && param3) {
            this.Query.condiciones.push(
                {
                    tipo: "orWhere",
                    columna: param1,
                    operador: param2,
                    condicion: param3
                }
            )
        }

        if (Array.isArray(param1) && !param2 && !param3) {

            // param1.forEach(condicion => {
            //     this.Query.condiciones.push(
            //         {
            //             tipo: "where",
            //             columna: param1,
            //             operador: param2,
            //             condicion: param3
            //         }
            //     )
            // });

        }
        return this
    }


    whereIn(param1, param2) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        if (typeof param1 === "string" && Array.isArray(param2)) {
            this.Query.condiciones.push(
                {
                    tipo: "whereIn",
                    columna: param1,
                    condicion: param2
                }
            )
        }

        return this
    }

    whereNotIn(param1, param2) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        if (typeof param1 === "string" && Array.isArray(param2)) {
            this.Query.condiciones.push(
                {
                    tipo: "whereNotIn",
                    columna: param1,
                    condicion: param2
                }
            )
        }

        return this
    }

    whereNull(param1) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        if (typeof param1 === "string") {
            this.Query.condiciones.push(
                {
                    tipo: "whereNull",
                    columna: param1,
                }
            )
        }

        return this
    }

    whereNotNull(param1) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        if (typeof param1 === "string") {
            this.Query.condiciones.push(
                {
                    tipo: "whereNotNull",
                    columna: param1,
                }
            )
        }
        return this
    }

    whereHas(relacion, fn = () => []) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        const result = [...fn(new EkQuery()).Query.condiciones];
        this.Query.condiciones.push(
            {
                tipo: "whereHas",
                relacion: relacion,
                condiciones: result,
            }
        )

        return this
    }

    whereDoesntHave(relacion, fn = () => []) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        const result = [...fn(new EkQuery()).Query.condiciones];
        this.Query.condiciones.push(
            {
                tipo: "whereDoesntHave",
                relacion: relacion,
                condiciones: result,
            }
        )

        return this
    }

    has(param1) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        if (typeof param1 === "string") {
            this.Query.condiciones.push(
                {
                    tipo: "has",
                    columna: param1,
                }
            )
        }

        return this
    }

    doesntHave(param1) {
        if (!this.Query.condiciones) {
            this.Query.condiciones = [];
        }
        if (typeof param1 === "string") {
            this.Query.condiciones.push(
                {
                    tipo: "doesntHave",
                    columna: param1,
                }
            )
        }

        return this
    }

    create(datos) {
        this.Query.tipo = "Crear";
        if (Array.isArray(datos)) {
            this.Query.data = [];
            datos.forEach(dat => {
                this.Query.data.push({
                    modelo: dat.modelo,
                    tipo: dat.tipo,
                    datos: dat.datos
                });
            });
        } else if (typeof datos === "object") {
            this.Query.datos = [];
            Object.entries(datos).forEach(([key, value]) => {
                this.Query.datos.push({
                    columna: key,
                    dato: value,
                })
            });
        }

        this.JsonParams()
        return this.Query;
    }

    insert(datos) {
        this.Query.tipo = "Crear";
        if (Array.isArray(datos)) {
            this.Query.inserts = [];
            datos.forEach(dat => {
                Object.entries(dat).forEach(([key, value]) => {
                    this.Query.inserts.push({
                        columna: key,
                        dato: value,
                    })
                });
            });
        }
        this.JsonParams()
        return this.Query;
    }

    uploadFile(file, carpeta, nombre, datos) {

        this.Query.tipo = "Archivos";
        this.Query.datos = [{
            columna: nombre,
            carpeta: carpeta,
        }];

        if (carpeta) {
            this.Query.datos.push(
                {
                    columna: "carpeta",
                    dato: carpeta,
                })
        }
        if (datos) {
            Object.entries(datos).forEach(([key, value]) => {
                this.Query.datos.push({
                    columna: key,
                    dato: value,
                })
            });
        }
        this.Query.file = file;
        this.JsonParams()
        return this.Query;
    }

    update(datos) {
        this.Query.tipo = "Editar";
        this.Query.datos = [];
        Object.entries(datos).forEach(([key, value]) => {
            this.Query.datos.push({
                columna: key,
                dato: value,
            })
        });
        this.JsonParams()
        return this.Query;
    }

    destroy(ids) {
        this.Query.tipo = "Destroy";
        if (Array.isArray(ids)) {
            this.Query.ids = ids;
        }
        this.JsonParams()
        return this.Query;
    }

    delete() {
        this.Query.tipo = "Borrar";
        this.JsonParams()
        return this.Query;
    }


    get() {
        this.Query.peticion = "Todos"
        this.JsonParams()
        return this.Query;
    }

    first() {
        this.Query.peticion = "Primero"
        this.JsonParams()
        return this.Query;
    }

    paginate(limit, page) {
        this.Query.peticion = "Paginado"
        this.Query.paginado = JSON.stringify({
            perpage: limit,
            current_page: page,
        })
        this.JsonParams()
        return this.Query;
    }

    JsonParams() {
        if (this.tipo === "Json" || this.tipo === "FormData") {
            if (this.Query.busqueda) this.Query.busqueda = JSON.stringify(this.Query.busqueda);
            if (this.Query.condiciones) this.Query.condiciones = JSON.stringify(this.Query.condiciones);
            if (this.Query.relaciones) this.Query.relaciones = JSON.stringify(this.Query.relaciones);
            if (this.Query.orden) this.Query.orden = JSON.stringify(this.Query.orden);
            if (this.Query.datos) this.Query.datos = JSON.stringify(this.Query.datos);
            if (this.Query.data) this.Query.data = JSON.stringify(this.Query.data);
            if (this.Query.inserts) this.Query.inserts = JSON.stringify(this.Query.inserts);

            if (this.tipo === "FormData") {
                var formulario = new FormData();
                Object.entries(this.Query).forEach(([key, value]) => {
                    if (key == "condiciones") {
                        formulario.append(key, value);
                        return
                    }
                    formulario.append(key, value);
                });
                this.Query = formulario;
            }
        }
    }
}

export { EkQuery };