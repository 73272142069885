import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'
import { ROL_PACIENTE } from '../js/rol'

const TABLA_PACIENTE = 'EurekaAcUsuario'
const TABLA_PACIENTE_FORM = 'EurekaAcUsuario'

export const pacienteAPI = createApi({
    reducerPath: 'pacienteAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Pacientes'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerPacientes: builder.query({
            query: ({ search = "", usuario }) => {
                let body = new EkQuery(TABLA_PACIENTE)
                body = body
                    .where('id', "!=", usuario)
                    .with('eureka_cr_multimedia')
                    .with('eureka_st_transacciones')
                    .with("eureka_cr_multimedia").get();
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },

        }),
        searchPaciente: builder.query({
            query: ({ search = "" }) => {
                let body = new EkQuery(TABLA_PACIENTE)
                if (search === "") {
                    body = body.where("id", 0)
                } else {
                    body = body.whereHas('eureka_cr_rol_usuarios', (q) => {
                        return q.where('rol', ROL_PACIENTE.id)
                    })
                        .search(search == "" ? null : search, ["nombre"])
                        .with('eureka_cr_multimedia')
                        .with('eureka_st_transacciones')
                        .with("eureka_cr_multimedia")

                }
                body = body.paginate(100, 1);
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
            transformResponse: resp => (resp.data),
        }),
        crearPaciente: builder.mutation({
            /**
             * 
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PACIENTE_FORM)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const {
    useObtenerPacientesQuery,
    useCrearPacienteMutation,
    useSearchPacienteQuery
} = pacienteAPI