import { MiPerfil } from 'eureka-design'
import nouser from '../../imgs/nouser.jpg'
import { useEffect, useState } from 'react'
import { ROL_DOCTOR } from '../../js/rol'
import { usuario as infoUsuario } from 'account-react-eurekasigma'

const Perfil = ({ rol, Push }) => {
    const [usuario] = useState(infoUsuario())
    const [opcionesDoctor, fijaOpcionesDoctor] = useState([])
    
    useEffect(() => {
        const handlerVerDocumentos = () => {
            Push('documentacion', {
                usuario: usuario
            })
        }
    
        const handlerVerGanancias = () => {
            Push('ganancia', {
                usuario: usuario
            })
        }

        if(rol === ROL_DOCTOR.nombre) {
            fijaOpcionesDoctor([
                { iconLeft: 'fa-file', texto: 'Documentación', onClick: handlerVerDocumentos },
                { iconLeft: 'fa-hand-holding-dollar', texto: 'Ganancias', onClick: handlerVerGanancias },
            ])
        }
        else {
            fijaOpcionesDoctor([])
        }
    }, [usuario, rol, Push])

    return (
        <MiPerfil 
            avatar={nouser}
            nombre={usuario?.nombre ?? 'Sin Nombre'}
            correo={usuario?.correo ?? 'Sin Correo'}
            telefono={usuario?.telefono ?? 'Sin Teléfono'}
            botones={[
                { iconLeft: 'fa-pen', texto: 'Editar Perfil', onClick: () => { window.location.href = '/cerrar_sesion' } },
                ...opcionesDoctor,
                { iconLeft: 'fa-right-from-bracket', texto: 'Cerrar Sesión', onClick: () => { window.location.href = '/cerrar_sesion' } },
            ]}
        />
    )
}

export default Perfil