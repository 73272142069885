import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_USUARIO = 'EurekaAcUsuario'

export const usuarioAPI = createApi({
    reducerPath: 'usuarioAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Usuarios'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerUsuario: builder.query({
            /**
             * 
             * @param {{ token: string, col: string, val: string }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${data.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_USUARIO)
                                .where(data.col, '=', data.val)
                                .with('eureka_pa_cuenta_fiscals')
                                .with('eureka_cr_rol_usuarios')
                                .with('eureka_cr_multimedia')
                                .first()
                }
            },
        }),
        obtenerUsuarios: builder.query({
            /**
             * 
             * @param {{ usuarioID: number }} data 
             * @returns 
             */
            query: (data) => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_USUARIO)
                                .where('id', '!=', data.usuarioID)
                                .with('eureka_pa_cuenta_fiscals')
                                .with('eureka_cr_rol_usuarios')
                                .with('eureka_cr_multimedia')
                                .with('eureka_pa_subscriptores.eureka_pa_subscripcione')
                                .get()
                }
            }
        }),
        crearUsuario: builder.mutation({
            /**
             * 
             * @param {{ id: int, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_USUARIO)
                if (data?.id) body = body.where("id", data.id).update(data.body)
                else body = body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: "POST",
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const { 
    useObtenerUsuarioQuery,
    useObtenerUsuariosQuery,
    useCrearUsuarioMutation,
} = usuarioAPI