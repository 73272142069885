import './TablaProductos.css'
import { numberFormat } from '../../js/util'
import Categorias from './Categorias/Categorias'
import CardProducto from './CardProducto/CardProducto'
import { Card, Select, Button, ButtonIcon } from 'eureka-design'

const TablaProductos = ({
    buquedad = {},
    botonesHeader = [],
    onClickCard = () => { },
    onClickCategoria = () => { },
    data = [],
    categorias = [],
}) => {
    return (
        <div className="contenedor-tabla-productos">
            <Categorias
                titulo={'Categorías'}
                categorias={categorias}
                onClick={(categorias) => {
                    onClickCategoria(categorias)
                }}
            />

            <Card>
                <div className="contenedor-tabla-productos__tabla">
                    <div className="contenedor-tabla-productos__tabla__header">
                        <div style={{ flexGrow: 3 }}>
                            <Select
                                nombre={buquedad?.nombre}
                                iconLeft={buquedad?.icono}
                                options={buquedad?.opciones}
                                value={buquedad?.valor ?? buquedad?.placeholder}
                                changeFunction={buquedad?.onChage}
                                busqueda={{ placeholder: buquedad?.placeholder }}
                            />
                        </div>
                        {
                            botonesHeader.map((b, i) => {
                                if (b?.texto?.length > 0)
                                    return (
                                        <Button
                                            key={i}
                                            iconLeft={b.icono}
                                            texto={b.texto}
                                            onClick={b.onClick}
                                        />
                                    )
                                else
                                    return (
                                        <ButtonIcon
                                            key={i}
                                            icono={b.icono}
                                            onClick={b.onClick}
                                            background="var(--color-primario)"
                                            secundario={true}
                                        />
                                    )
                            })
                        }
                    </div>

                    <div className="contenedor-tabla-productos__tabla__grid">
                        {
                            data.map((d, i) => {
                                return (
                                    <CardProducto
                                        key={i}
                                        id={d.id}
                                        foto={d.foto}
                                        codigo={d.codigo}
                                        precio={`${numberFormat(d.precio)}`}
                                        costo={`${numberFormat(d.costo)}`}
                                        inventario={d.inventario}
                                        principios={d.principios}
                                        onClick={() => { onClickCard(d) }}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default TablaProductos