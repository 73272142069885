import { useEffect, useState } from 'react'
import './CardArchivo.css'

const CardArchivo = ({ 
    titulo = '', 
    nombre = '', 
    onDelete = () => {}, 
    onDownload = () => {},
    permitirEliminar = true,
}) => {
    const [estructuraNombre, fijaEstructuraNombre] = useState([])

    useEffect(() => {
        if(nombre?.length > 0) {
            let estructuraNombre = nombre.split('.')
            fijaEstructuraNombre(estructuraNombre?.length === 2 ? estructuraNombre : [])
        }
        // else el nombre queda igual
    }, [fijaEstructuraNombre, nombre])

    return (
        <div className="card-info-archivo" onClick={onDownload}>
            { permitirEliminar ? 
                <i 
                    className="fa-solid fa-circle-xmark card-info-archivo__icono-cerrar"
                    onClick={(e) => {
                        e.stopPropagation()
                        onDelete() 
                    }}
                ></i> : null
            }
            <p className="card-info-archivo__titulo">{titulo}</p>
            <i className="fa-solid fa-file-lines card-info-archivo__icono"></i>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p className="card-info-archivo__nombre">
                    {estructuraNombre?.length === 0 ? nombre : estructuraNombre[0]}
                </p>
                <p className="card-info-archivo__extension">.{estructuraNombre[1] ?? ''}</p>
            </div>
        </div>
    )
}

export default CardArchivo