import './CardProducto.css'
import sinFoto from '../../../imgs/noimage.jpg'
import React, { useMemo } from 'react'
import { numberFormat } from '../../../js/util'

const CardProducto = React.memo(({
    foto = null,
    codigo = '',
    id = '',
    precio = '',
    costo = '',
    inventario = 0,
    principios = [],
    onClick = () => { },
}) => {
    const card = useMemo(() => {
        return (
            <div className="card-producto-tabla" onClick={onClick}>
                <img className="card-producto-tabla__foto" src={foto ?? sinFoto} alt="foto producto" />
                <div className="card-producto-tabla__informacion">
                    <p className="card-producto-tabla__informacion__titulo">{codigo}</p>
                    <p className="card-producto-tabla__informacion__extra"><font className="informacion__extra__subtile">Precio 1: </font>{precio}</p>
                    {/* <p className="card-producto-tabla__informacion__extra"><font className="informacion__extra__subtile">Costo: </font>{costo}</p> */}

                    <p className="card-producto-tabla__informacion__extra">
                        <font className="informacion__extra__subtile">Activos: </font> {
                            principios.map((principio, i) => {
                                return (<font key={i}>{principio.caracteristica} {principio.valor} </font>)
                            })
                        }
                    </p>
                    <p className="card-producto-tabla__informacion__extra">
                        <font className="informacion__extra__subtile">Almacén: </font> <font>{inventario}</font> piezas
                    </p>
                </div>
            </div>
        )
    }, [foto, codigo, id, precio, inventario, onClick])

    return card
})

export default CardProducto